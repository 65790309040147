import CdnImage from 'components/util/CdnImage'
// import CloudinaryImage from 'components/util/CloudinaryImage'
import React, { useEffect } from 'react'

interface Icon {
  imgSrc: any
  alt: string
  tag: string
  iconImage: any
  builderIconImage: any
}

interface Props {
  backgroundColor: string
  icons: Icon[]
}

const IconCloud = ({ backgroundColor = '', icons }: Props) => {
  const threeIcons = icons.slice(0, icons.length - 1)

  const getFourIcons = () => {
    return icons.map((icon, i) => (
      <div style={{ maxHeight: '62px' }} className={`mt-4 flex w-1/4 flex-row justify-center`} key={i}>
        <CdnImage
          className="object-contain"
          url={icon.builderIconImage}
          alt={icon.alt}
          steps={[150, 205]}
          style={{
            height: '72px',
            maxHeight: '100%',
            maxWidth: '100%'
          }}
        />

        {/* {icon.builderIconImage ? (
          <CdnImage
            className="object-contain"
            url={icon.builderIconImage}
            alt={icon.alt}
            steps={[150, 205]}
            style={{
              height: '72px',
              maxHeight: '100%',
              maxWidth: '100%'
            }}
          />
        ) : (
          <CloudinaryImage
            className="object-contain"
            publicId={icon?.iconImage?.public_id}
            alt={icon.alt}
            steps={[150, 205]}
            autoFormat={false}
            style={{
              height: '72px',
              maxHeight: '100%',
              maxWidth: '100%'
            }}
          />
        )} */}
        <p className="ml-2 flex items-center justify-center whitespace-nowrap"> {icon.tag} </p>
      </div>
    ))
  }
  const getThreeIcons = () => {
    return threeIcons.map((icon: any, i: number) => (
      <div style={{ maxHeight: '62px' }} className={`mt-4 flex w-1/4 flex-col justify-center `} key={i}>
        <CdnImage
          className="object-contain"
          url={icon.builderIconImage}
          alt={icon.alt}
          steps={[150, 205]}
          style={{
            height: '72px',
            maxHeight: '100%',
            maxWidth: '100%'
          }}
        />

        {/* {icon.builderIconImage ? (
          <CdnImage
            className="object-contain"
            url={icon.builderIconImage}
            alt={icon.alt}
            steps={[150, 205]}
            style={{
              height: '72px',
              maxHeight: '100%',
              maxWidth: '100%'
            }}
          />
        ) : (
          <CloudinaryImage
            className="object-contain"
            publicId={icon?.iconImage?.public_id}
            steps={[150, 205]}
            alt={icon.alt}
            autoFormat={false}
            style={{
              height: '72px',
              maxHeight: '100%',
              maxWidth: '100%'
            }}
          />
        )} */}
        <p className="flex items-center justify-center text-center text-sm"> {icon.tag} </p>
      </div>
    ))
  }

  return (
    <div className="-mb-2 bg-contain pb-6 lg:mb-0 lg:pb-0" style={{ backgroundColor }}>
      <div className="mx-auto -mt-4 flex w-11/12 flex-row justify-center gap-x-8 py-6 lg:hidden">{getThreeIcons()}</div>
      <div className="mx-auto -mb-4 -mt-8 hidden w-11/12 2xl:w-4/5 flex-row justify-center gap-x-1 lg:flex lg:pt-4 lg:pb-8">
        {getFourIcons()}
      </div>
    </div>
  )
}

export default IconCloud
